.ControlPane {
    position: fixed;
    top: 0.25rem;
    right: 0.25rem;
    width: 200px;
    z-index: 1;
    display: grid;
    grid-template-columns: auto;
    grid-auto-flow: row;
    grid-auto-rows: min-content;
    gap: 1rem;
}
