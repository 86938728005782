.SlideMiniature {
    box-sizing: border-box;
    position: relative;
    width: 184px;
    height: 124px;
    border: 2px solid var(--light-gray-1);
    border-radius: 0.25rem;
    margin-bottom: 0.5rem;
    overflow: hidden;
    user-select: none;
    display: grid;
    font-size: 2rem;
}

.SlideMiniatureLayer {
    width: 100%;
    height: 100%;
    grid-column: 1;
    grid-row: 1;
    display: flex;
    justify-content: center;
    align-items: center;
}

.SelectedSlideMiniature {
    border-color: var(--orange);
}

.NonRenderingSlideMiniatureMask {
    z-index: 1;
    background: rgba(255, 255, 255, 0.8);
    color: var(--light-gray-4);
}
