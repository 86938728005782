.SlideMiniatureImage {
    display: flex;
    align-items: center;
    justify-content: center;
}

.WidthCappedSlideMiniatureImage canvas {
    width: 180px;
    height: auto;
}

.HeightCappedSlideMiniatureImage canvas {
    width: auto;
    height: 120px;
}
