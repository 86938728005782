.CTABanner {
    z-index: 1;
    position: fixed;
    --width: 460px;
    width: var(--width);
    left: calc((100% - var(--width)) / 2);
    bottom: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.CTABanner .CTABannerLink {
    margin-right: 0.25rem;
}
