.ViewInputRadioButton {
    width: 100%;
    height: 2.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--dark-gray);
    font-size: 0.9rem;
    cursor: pointer;
    background-color: var(--light-gray-1);
    border-radius: 0.25rem;
}

.ActiveViewInputRadioButton,
.ViewInputRadioButton:hover {
    color: black;
    background-color: var(--light-gray-3);
}
