.PrintableAreaInput {
    color: var(--dark-gray);
    font-size: 0.75rem;
    font-weight: bold;
    margin-bottom: 0.5rem;
}

.PrintableAreaInput > label {
    display: block;
    margin-bottom: 0.5rem;
}

.PrintableAreaInput .PositiveIntegerInput label {
    width: 4rem;
}
