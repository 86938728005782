.PositiveIntegerInput {
    display: flex;
    align-items: center;
    color: var(--dark-gray);
    font-size: 0.75rem;
    font-weight: bold;
    margin-bottom: 0.25rem;
}

.PositiveIntegerInput input {
    box-sizing: border-box;
    width: 100%;
    height: 1.875em;
    margin-left: 0.25rem;
    padding: 1px;
    padding-inline-start: 0.5em;
    border: 1px solid var(--light-gray-2);
    border-radius: 0.25rem;
    color: var(--dark-gray);
    font-size: 1rem;
    outline: none;
}
