.SlidesControl {
    max-height: calc(100vh - 270px);
    width: 184px;
    overflow-y: scroll;
}

/* Hide scrollbars */
/* Firefox */
.SlidesControl {
    scrollbar-width: none;
}
/* WebKit + Blink browsers */
.SlidesControl::-webkit-scrollbar {
    width: 0;
    height: 0;
}
