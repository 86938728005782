.CTABannerLink {
    --purple: #862e9c;
    --light-purple: #eee4f1;
    width: 200px;
    padding: 0.75rem;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--light-purple);
    color: var(--purple);
    border: 2px solid var(--purple);
    border-radius: 0.25rem;
    font-size: 1.5rem;
    cursor: pointer;
    text-decoration: none;
    font-family: Virgil;
    user-select: none;
    transition: all 0.1s linear;
}

.CTABannerLink:visited {
    color: var(--purple);
}

.CTABannerLink:hover {
    background-color: var(--purple);
    color: white;
}

.CTABannerLinkIcon {
    margin-right: 0.5rem;
}
