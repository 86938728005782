.IconButton {
    cursor: pointer;
    width: 2.5rem;
    height: 2.5rem;
    border: 0px;
    border-radius: 0.25rem;
    font-size: 1rem;
    background-color: var(--light-gray-1);
}

.IconButton:hover:not([disabled]) {
    background-color: var(--light-gray-3);
}

.IconButton:disabled {
    cursor: not-allowed;
}
